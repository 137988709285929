/* eslint-disable */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
  @font-face {
    font-family: "Jet Brains Mono";
    src: url("/assets/fonts/JetBrainsMono-Regular.woff2") format("woff2");
  }
} */
.navbar {
  min-height: 5rem;
}

/* Input DatePicker CSS Start */
.react-datepicker__input-container input {
  @apply block w-full rounded border border-gray-300 bg-white text-base shadow-sm md:text-sm;
}

.react-datepicker__tab-loop {
  @apply absolute;
  top: 22px;
  left: -2px;
}

.react-datepicker {
  @apply cursor-pointer;
}

.react-datepicker-popper {
  @apply absolute top-0 z-50 mt-2 w-64 px-1 py-1 text-sm;
  @apply rounded border-2 border-gray-200 bg-white shadow;
}

.react-datepicker-left {
  @apply absolute left-0 top-0 z-50;
}

.react-datepicker-right {
  @apply relative right-0 top-0 z-40 transform-none !important;
}

.react-datepicker__portal {
  @apply relative z-10 w-72 transform-none text-sm;
  @apply right-0 top-12 rounded border-2 border-gray-200 bg-white px-3 py-2 shadow;
}

/* DATE PICKER MONTH & WEEK */
.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

/* DATE PICKER NAVIGATION */
.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 flex h-8 w-8 items-center justify-center rounded transition hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 flex h-8 w-8 items-center justify-center rounded transition hover:bg-gray-200;
}

/* DATE PICKER DAY */
.react-datepicker__day {
  @apply mb-1 flex h-6 w-6 items-center justify-center rounded py-1 text-xs leading-loose text-gray-700 transition;
}

.react-datepicker__day-names {
  @apply flex justify-around text-center text-xs font-medium text-gray-400;
}

.react-datepicker__day-name {
  @apply flex h-8 w-8 items-center justify-center rounded-full py-1;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-primary-200;
}

.react-datepicker__day--selecting-range-start {
  @apply border-2 border-primary-500 bg-white;
}

.react-datepicker__day--selecting-range-end {
  @apply border-2 border-primary-500 bg-white;
}

.react-datepicker__day--selected {
  @apply bg-primary-500 text-white;
}

.react-datepicker__day--range-start {
  @apply bg-primary-500 text-white hover:bg-white hover:text-gray-700;
}

.react-datepicker__day--range-end {
  @apply bg-primary-500 text-white hover:bg-white hover:text-gray-700;
}

.react-datepicker__input-time-container {
  @apply my-4 flex gap-2;
}

.react-datepicker__aria-live {
  display: none;
}

.react-datepicker__aria-live {
  display: none;
}

/* DATE PICKER TIME */
.react-datepicker-time__input-container {
  @apply rounded-md border border-primary-700;
}

.react-datepicker-time__input {
}

.react-datepicker-time__caption {
}

/* Input DatePicker CSS End */
/* RC TREE CSS Start */
.rc-tree-title {
  vertical-align: top !important;
}

.rc-tree .rc-tree-treenode span.rc-tree-switcher,
.rc-tree .rc-tree-treenode span.rc-tree-checkbox,
.rc-tree .rc-tree-treenode span.rc-tree-iconEle {
  vertical-align: middle !important;
}

.rc-tree-icon__customize {
  margin-right: 10px !important;
  vertical-align: sub !important;
}

.rc-tree-switcher > svg {
  background-color: transparent !important;
}

.rc-tree .rc-tree-treenode span.rc-tree-switcher.rc-tree-switcher_open {
  background-image: none;
}

.rc-tree .rc-tree-treenode span.rc-tree-switcher.rc-tree-switcher_close {
  background-image: none;
}

.rc-tree-node-content-wrapper {
  @apply px-1 !important;
}

.rc-tree-treenode {
  @apply px-4 py-0.5 text-sm !important;
}

.rc-tree-treenode-selected {
  @apply border-r-4 border-primary-700 bg-primary-200 !important;
}

.rc-tree-node {
  @apply py-0.5 !important;
}

.rc-tree-iconEle .rc-tree-icon__customize > span > svg {
  display: inline-block;
  vertical-align: bottom;
}

.rc-tree-node-selected {
  @apply bg-transparent shadow-none !important;
}

.rc-tree-node-content-wrapper-normal {
  @apply text-sm !important;
}

.drop-target {
  @apply bg-primary-500 text-white !important;
}

.rc-tree-node-content-wrapper .rc-treenode-content-wrapper-normal > div {
  background-color: #009ca6 !important;
}

/* RC TREE CSS End */

/* Tooltip CSS Start */
/* TODO burası tailwind dönüştürülecek dosya içine alınacak*/
.tooltip-container {
  position: relative;
}

.tooltip-box {
  position: absolute;
  background: #fff;
  color: black;
  z-index: 100;
  padding: 5px;
  border-radius: 5px;
  /* top: calc(100% + 5px); */
  top: 0px;
  left: 40px;
  display: none;
}

.tooltip-box.visible {
  display: block;
}

.tooltip-arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

/* Tooltip CSS End */
/* Input Range CSS Start */
/* input[type="range"]::-moz-range-thumb {
    @apply w-5 h-5 bg-red-500 cursor-pointer rounded-full border-white border-2 shadow-md outline-none
  }

  input[type="range"]::-moz-range-track {
    @apply bg-green-700 border-none h-1/2 rounded-md
  }

  input[type="range"]::-moz-range-progress {
    @apply bg-primary-500 border-none rounded-md h-1/2
  }

  input[type="range"]{
    @apply w-full h-2 outline-none border-none  rounded-md !important;
    -webkit-appreance:none ;
  } */
input[type='range'] {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 50px;
  overflow: hidden;
  width: 240px;
}

input[type='range']:focus {
  box-shadow: none;
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  @apply rounded-full bg-primary-600;
  content: '';
  height: 4px;
  pointer-events: none;
}

input[type='range']::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  appearance: none;
  @apply rounded-full bg-primary-600 shadow-md;
  box-shadow: 5px 0 0 -8px #c7c7c7, 6px 0 0 -8px #c7c7c7, 7px 0 0 -8px #c7c7c7, 8px 0 0 -8px #c7c7c7,
    9px 0 0 -8px #c7c7c7, 10px 0 0 -8px #c7c7c7, 11px 0 0 -8px #c7c7c7, 12px 0 0 -8px #c7c7c7, 13px 0 0 -8px #c7c7c7,
    14px 0 0 -8px #c7c7c7, 15px 0 0 -8px #c7c7c7, 16px 0 0 -8px #c7c7c7, 17px 0 0 -8px #c7c7c7, 18px 0 0 -8px #c7c7c7,
    19px 0 0 -8px #c7c7c7, 20px 0 0 -8px #c7c7c7, 21px 0 0 -8px #c7c7c7, 22px 0 0 -8px #c7c7c7, 23px 0 0 -8px #c7c7c7,
    24px 0 0 -8px #c7c7c7, 25px 0 0 -8px #c7c7c7, 26px 0 0 -8px #c7c7c7, 27px 0 0 -8px #c7c7c7, 28px 0 0 -8px #c7c7c7,
    29px 0 0 -8px #c7c7c7, 30px 0 0 -8px #c7c7c7, 31px 0 0 -8px #c7c7c7, 32px 0 0 -8px #c7c7c7, 33px 0 0 -8px #c7c7c7,
    34px 0 0 -8px #c7c7c7, 35px 0 0 -8px #c7c7c7, 36px 0 0 -8px #c7c7c7, 37px 0 0 -8px #c7c7c7, 38px 0 0 -8px #c7c7c7,
    39px 0 0 -8px #c7c7c7, 40px 0 0 -8px #c7c7c7, 41px 0 0 -8px #c7c7c7, 42px 0 0 -8px #c7c7c7, 43px 0 0 -8px #c7c7c7,
    44px 0 0 -8px #c7c7c7, 45px 0 0 -8px #c7c7c7, 46px 0 0 -8px #c7c7c7, 47px 0 0 -8px #c7c7c7, 48px 0 0 -8px #c7c7c7,
    49px 0 0 -8px #c7c7c7, 50px 0 0 -8px #c7c7c7, 51px 0 0 -8px #c7c7c7, 52px 0 0 -8px #c7c7c7, 53px 0 0 -8px #c7c7c7,
    54px 0 0 -8px #c7c7c7, 55px 0 0 -8px #c7c7c7, 56px 0 0 -8px #c7c7c7, 57px 0 0 -8px #c7c7c7, 58px 0 0 -8px #c7c7c7,
    59px 0 0 -8px #c7c7c7, 60px 0 0 -8px #c7c7c7, 61px 0 0 -8px #c7c7c7, 62px 0 0 -8px #c7c7c7, 63px 0 0 -8px #c7c7c7,
    64px 0 0 -8px #c7c7c7, 65px 0 0 -8px #c7c7c7, 66px 0 0 -8px #c7c7c7, 67px 0 0 -8px #c7c7c7, 68px 0 0 -8px #c7c7c7,
    69px 0 0 -8px #c7c7c7, 70px 0 0 -8px #c7c7c7, 71px 0 0 -8px #c7c7c7, 72px 0 0 -8px #c7c7c7, 73px 0 0 -8px #c7c7c7,
    74px 0 0 -8px #c7c7c7, 75px 0 0 -8px #c7c7c7, 76px 0 0 -8px #c7c7c7, 77px 0 0 -8px #c7c7c7, 78px 0 0 -8px #c7c7c7,
    79px 0 0 -8px #c7c7c7, 80px 0 0 -8px #c7c7c7, 81px 0 0 -8px #c7c7c7, 82px 0 0 -8px #c7c7c7, 83px 0 0 -8px #c7c7c7,
    84px 0 0 -8px #c7c7c7, 85px 0 0 -8px #c7c7c7, 86px 0 0 -8px #c7c7c7, 87px 0 0 -8px #c7c7c7, 88px 0 0 -8px #c7c7c7,
    89px 0 0 -8px #c7c7c7, 90px 0 0 -8px #c7c7c7, 91px 0 0 -8px #c7c7c7, 92px 0 0 -8px #c7c7c7, 93px 0 0 -8px #c7c7c7,
    94px 0 0 -8px #c7c7c7, 95px 0 0 -8px #c7c7c7, 96px 0 0 -8px #c7c7c7, 97px 0 0 -8px #c7c7c7, 98px 0 0 -8px #c7c7c7,
    99px 0 0 -8px #c7c7c7, 100px 0 0 -8px #c7c7c7, 101px 0 0 -8px #c7c7c7, 102px 0 0 -8px #c7c7c7,
    103px 0 0 -8px #c7c7c7, 104px 0 0 -8px #c7c7c7, 105px 0 0 -8px #c7c7c7, 106px 0 0 -8px #c7c7c7,
    107px 0 0 -8px #c7c7c7, 108px 0 0 -8px #c7c7c7, 109px 0 0 -8px #c7c7c7, 110px 0 0 -8px #c7c7c7,
    111px 0 0 -8px #c7c7c7, 112px 0 0 -8px #c7c7c7, 113px 0 0 -8px #c7c7c7, 114px 0 0 -8px #c7c7c7,
    115px 0 0 -8px #c7c7c7, 116px 0 0 -8px #c7c7c7, 117px 0 0 -8px #c7c7c7, 118px 0 0 -8px #c7c7c7,
    119px 0 0 -8px #c7c7c7, 120px 0 0 -8px #c7c7c7, 121px 0 0 -8px #c7c7c7, 122px 0 0 -8px #c7c7c7,
    123px 0 0 -8px #c7c7c7, 124px 0 0 -8px #c7c7c7, 125px 0 0 -8px #c7c7c7, 126px 0 0 -8px #c7c7c7,
    127px 0 0 -8px #c7c7c7, 128px 0 0 -8px #c7c7c7, 129px 0 0 -8px #c7c7c7, 130px 0 0 -8px #c7c7c7,
    131px 0 0 -8px #c7c7c7, 132px 0 0 -8px #c7c7c7, 133px 0 0 -8px #c7c7c7, 134px 0 0 -8px #c7c7c7,
    135px 0 0 -8px #c7c7c7, 136px 0 0 -8px #c7c7c7, 137px 0 0 -8px #c7c7c7, 138px 0 0 -8px #c7c7c7,
    139px 0 0 -8px #c7c7c7, 140px 0 0 -8px #c7c7c7, 141px 0 0 -8px #c7c7c7, 142px 0 0 -8px #c7c7c7,
    143px 0 0 -8px #c7c7c7, 144px 0 0 -8px #c7c7c7, 145px 0 0 -8px #c7c7c7, 146px 0 0 -8px #c7c7c7,
    147px 0 0 -8px #c7c7c7, 148px 0 0 -8px #c7c7c7, 149px 0 0 -8px #c7c7c7, 150px 0 0 -8px #c7c7c7,
    151px 0 0 -8px #c7c7c7, 152px 0 0 -8px #c7c7c7, 153px 0 0 -8px #c7c7c7, 154px 0 0 -8px #c7c7c7,
    155px 0 0 -8px #c7c7c7, 156px 0 0 -8px #c7c7c7, 157px 0 0 -8px #c7c7c7, 158px 0 0 -8px #c7c7c7,
    159px 0 0 -8px #c7c7c7, 160px 0 0 -8px #c7c7c7, 161px 0 0 -8px #c7c7c7, 162px 0 0 -8px #c7c7c7,
    163px 0 0 -8px #c7c7c7, 164px 0 0 -8px #c7c7c7, 165px 0 0 -8px #c7c7c7, 166px 0 0 -8px #c7c7c7,
    167px 0 0 -8px #c7c7c7, 168px 0 0 -8px #c7c7c7, 169px 0 0 -8px #c7c7c7, 170px 0 0 -8px #c7c7c7,
    171px 0 0 -8px #c7c7c7, 172px 0 0 -8px #c7c7c7, 173px 0 0 -8px #c7c7c7, 174px 0 0 -8px #c7c7c7,
    175px 0 0 -8px #c7c7c7, 176px 0 0 -8px #c7c7c7, 177px 0 0 -8px #c7c7c7, 178px 0 0 -8px #c7c7c7,
    179px 0 0 -8px #c7c7c7, 180px 0 0 -8px #c7c7c7, 181px 0 0 -8px #c7c7c7, 182px 0 0 -8px #c7c7c7,
    183px 0 0 -8px #c7c7c7, 184px 0 0 -8px #c7c7c7, 185px 0 0 -8px #c7c7c7, 186px 0 0 -8px #c7c7c7,
    187px 0 0 -8px #c7c7c7, 188px 0 0 -8px #c7c7c7, 189px 0 0 -8px #c7c7c7, 190px 0 0 -8px #c7c7c7,
    191px 0 0 -8px #c7c7c7, 192px 0 0 -8px #c7c7c7, 193px 0 0 -8px #c7c7c7, 194px 0 0 -8px #c7c7c7,
    195px 0 0 -8px #c7c7c7, 196px 0 0 -8px #c7c7c7, 197px 0 0 -8px #c7c7c7, 198px 0 0 -8px #c7c7c7,
    199px 0 0 -8px #c7c7c7, 200px 0 0 -8px #c7c7c7, 201px 0 0 -8px #c7c7c7, 202px 0 0 -8px #c7c7c7,
    203px 0 0 -8px #c7c7c7, 204px 0 0 -8px #c7c7c7, 205px 0 0 -8px #c7c7c7, 206px 0 0 -8px #c7c7c7,
    207px 0 0 -8px #c7c7c7, 208px 0 0 -8px #c7c7c7, 209px 0 0 -8px #c7c7c7, 210px 0 0 -8px #c7c7c7,
    211px 0 0 -8px #c7c7c7, 212px 0 0 -8px #c7c7c7, 213px 0 0 -8px #c7c7c7, 214px 0 0 -8px #c7c7c7,
    215px 0 0 -8px #c7c7c7, 216px 0 0 -8px #c7c7c7, 217px 0 0 -8px #c7c7c7, 218px 0 0 -8px #c7c7c7,
    219px 0 0 -8px #c7c7c7, 220px 0 0 -8px #c7c7c7, 221px 0 0 -8px #c7c7c7, 222px 0 0 -8px #c7c7c7,
    223px 0 0 -8px #c7c7c7, 224px 0 0 -8px #c7c7c7, 225px 0 0 -8px #c7c7c7, 226px 0 0 -8px #c7c7c7,
    227px 0 0 -8px #c7c7c7, 228px 0 0 -8px #c7c7c7, 229px 0 0 -8px #c7c7c7, 230px 0 0 -8px #c7c7c7,
    231px 0 0 -8px #c7c7c7, 232px 0 0 -8px #c7c7c7, 233px 0 0 -8px #c7c7c7, 234px 0 0 -8px #c7c7c7,
    235px 0 0 -8px #c7c7c7, 236px 0 0 -8px #c7c7c7, 237px 0 0 -8px #c7c7c7, 238px 0 0 -8px #c7c7c7,
    239px 0 0 -8px #c7c7c7, 240px 0 0 -8px #c7c7c7;
  margin-top: -8px;
  border: 2px solid white;
}

input[type='range']::-moz-range-track {
  height: 2px;
}

input[type='range']::-moz-range-thumb {
  height: 18px;
  width: 28px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #777;
  position: relative;
}

input[type='range']::-moz-range-progress {
  height: 2px;
  background: #e33d44;
  border: 0;
  margin-top: 0;
}

input[type='range']::-ms-track {
  background: transparent;
  border: 0;
  border-color: transparent;
  border-radius: 0;
  border-width: 0;
  color: transparent;
  height: 2px;
  margin-top: 10px;
  width: 240px;
}

input[type='range']::-ms-thumb {
  height: 18px;
  width: 28px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #777;
}

input[type='range']::-ms-fill-lower {
  background: #e33d44;
  border-radius: 0;
}

input[type='range']::-ms-fill-upper {
  background: #c7c7c7;
  border-radius: 0;
}

input[type='range']::-ms-tooltip {
  display: none;
}

/* Input Range CSS End*/
.list-box:hover {
  background-color: #eff6ff;
}

.list-box-active {
  background-color: #3b82f6 !important;
}

.list-box {
  @apply mb-2 flex cursor-pointer flex-col rounded-md p-3;
  background-color: #eff6ff;
  border: 1px solid #dedede;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.Toastify__toast {
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
}

html {
  scrollbar-width: thin;
}

html::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

html::-webkit-scrollbar-thumb,
#aside::-webkit-scrollbar-thumb {
  @apply rounded bg-primary-100;
}

html {
  scrollbar-color: #c7d2fe #eef2ff;
}

.progress-blue {
  @apply relative overflow-hidden;
  background-color: rgba(0, 155, 255, 0.7);
}

/*Scroll css for Windows*/
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-primary-100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-primary-200;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-primary-300;
}

.progress-blue::after {
  @apply absolute bottom-0 left-0 right-0 top-0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(233, 233, 233, 1) 0,
    rgba(233, 233, 233, 0.9) 50%,
    rgba(233, 233, 233, 0.8) 100%
  );
  animation: shimmer 2.5s ease-out infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}

.table-container {
  max-width: 900px;
}

@media (min-width: 1400px) {
  .table-container {
    max-width: 1000px;
  }
}

.fullpage-scroll-container {
  @apply overflow-y-hidden pr-4;
  height: 71vh;
}

.treeview-container {
  height: calc(100vh - 128px) !important;
  @apply block overflow-x-hidden overflow-y-hidden !important;
}

.rightPane-container {
  height: calc(100vh - 128px) !important;
  @apply block overflow-y-auto overflow-x-hidden !important;
}

.systemUser-treeview-container {
  height: calc(100vh - 200px) !important;
  @apply block divide-y overflow-y-auto overflow-x-hidden !important;
}
.dashboard-scroll-container {
  height: calc(100vh - 215px) !important;
  @apply block overflow-y-auto overflow-x-hidden !important;
}

.dashboard-scroll-container {
  height: calc(100vh - 215px) !important;
  @apply block overflow-y-auto overflow-x-hidden !important;
}

.treeview-buttons {
  @apply flex gap-2 border-b border-gray-200 px-2 pb-4 pr-2 pt-4;
  height: 65px;
}

.treeview-heading {
  @apply flex gap-2 px-2 pb-4 pr-2 pt-4;
  height: 72px;
}

.testCase-table-container {
  width: calc(100vw - 20rem);
  @apply px-8 pt-4;
}

.userSearch {
  height: calc(100vh - 4rem);
}

/**/
.tippy-box[data-theme~='custom-theme'] {
  @apply text-sm text-gray-700 shadow-md;
  background-color: white;
}

.tippy-box[data-theme~='custom-theme'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: #f6f6f8;
}

.tippy-box[data-theme~='custom-theme'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: #f6f6f8;
}

.tippy-box[data-theme~='custom-theme'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: white;
}

.checkboxColumn-td {
  @apply flex items-center justify-center;
}

.checkboxColumn-header {
  @apply flex items-center justify-center;
  margin-right: -20px;
}

/* BAR STYLES */
.bar {
  @apply inline-flex h-4 w-full overflow-hidden;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  border-radius: 3px;
  background: #eee;
}

.bar-fill {
  @apply inline-block h-4 overflow-hidden text-center;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
}

.bar-fill-success {
  background: #fd5a3e;
}

.bar-fill-failed {
  background: #97cc64;
}

.bar-fill-other {
  background: #dce0d7;
}

/* Table CSS */

.table {
  @apply w-full overflow-x-auto rounded-md border border-solid border-gray-200;
}

.thead {
  overflow-y: auto;
}

.resizer {
  @apply absolute right-0 top-0 flex h-full w-4 touch-none items-center text-gray-500;
}

.isResizing {
  @apply scale-125 text-gray-700;
}

.table-container {
  @apply block overflow-auto p-4;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 12px;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  color: #6b7280;
  padding: 4px;
  font-size: 15px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: '\00d7';
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.grid-cols-span-12-11 {
  grid-column: 'span 12 / span 11';
}

.grid-col-none {
  grid-column: none;
}

.z-9999 {
  z-index: 9999;
}

.elipsis {
  text-decoration: none;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
}

#article-viewer {
  max-width: 840px;
  margin: 0 auto;
}
.article-paragraph br {
  content: '';
}

.article-paragraph br:after {
  content: ' ';
}

.markdownViewer a {
  @apply cursor-pointer text-sm font-medium text-blue-900 underline-offset-4 hover:underline;
}

.placeholder-style::placeholder {
  color: white;
}
