@tailwind base;
@tailwind components;
@tailwind utilities;

.tree {
  display: inline-flex;
  flex-direction: column;
  width: max-content;
  background: rgb(249, 250, 251);
}

.row {
  display: inline-flex;
  width: 100%;
  white-space: nowrap;
  @apply pl-2 text-sm !important;
}

.node {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 0.75rem !important;
  height: 30px !important;
}

.row .node:global(.willReceiveDrop) {
  @apply bg-primary-100 !important;
}

.node:global(.isSelected) {
  @apply border-r-4 border-primary-700 bg-primary-200 !important;
}

.row:focus {
  outline: none;
}

.row:focus .node:global(.isSelected) {
  @apply border-r-4 border-primary-700 bg-primary-200 !important;
}

.icon {
  flex-shrink: 0;
  margin-right: 10px !important;
  vertical-align: sub !important;
}

.noIcon {
  display: none;
}

.text {
  cursor: pointer;
  white-space: nowrap;
}

.arrow {
  cursor: pointer;
  width: 25px;
  display: flex;
  @apply text-sm !important;
}