
.overview-example__add {
    display: none;
  }
  
  .react-flow__node a {
    font-weight: 700;
    color: #111;
  }
  
  .react-flow__node.dark-node {
    background: #0041d0;
    color: #f8f8f8;
  }
  
  .react-flow__node.dark {
    background: #557;
    color: #f8f8f8;
  }
  
  .react-flow__node-selectorNode {
    font-size: 12px;
    background: #f0f2f3;
    border: 1px solid 555;
    border-radius: 5px;
    text-align: center;
  }
  .edgebutton{
    background: #eee;
    border: 1px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
    height: 20px;
    line-height: 1;
    width: 20px;

  }
  .react-flow__node-selectorNode .react-flow__handle {
    border-color: #f0f2f3;
  }
  
  @media screen and (min-width: 768px) {
    .overview-example__add {
      display: block;
    }
  }