.sp_rightContent--main {
  @apply grid grid-cols-12;
  height: calc(100% - 4rem);
}

.sp_rightContent-Full-Page--main {
  @apply grid grid-cols-12;
  height: 100vh;
}

.sp_rightContent--center {
  @apply overflow-y-auto pb-4 pl-6 pr-6;
  height: calc(100% - 8rem);
}
